$mq_lg: "screen and (min-width: 1220px) and (min-height: 750px)";
$mq_md: "screen and (min-width: 1020px) and (min-height: 600px)";
$mq_sm: "screen and (min-width: 820px) and (min-height: 450px)";

$lg_width: 1200px;
$lg_height: 750px;

$md_width: 1000px;
$md_height: 600px;

$sm_width: 800px;
$sm_height: 450px;

// Main color: #0f75bc
// Accent color: #d91b5c

$color_background: #00A8AB;

$color_bright: #F9D50C;
$color_main: #0F5E60;
$color_accent: #D05878;

$color_highlight: rgb(255, 128, 0);

$color_clickable: #A12D4C;
$color_clickable_hover: lighten(#A12D4C, 15%);

$default-font-family: "Lato", sans-serif;

$color_success: #35B668;
$color_error: #C93534;