@import '../../../vars.scss';

.OldCard {
  display: inline-block;

  width: 100%;

  letter-spacing: 0;
  border: solid 1px #979797;
  background: linear-gradient(#fff, #eee);
  box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.5);

  border-radius: 0.4em;

  box-sizing: border-box;

  margin: 1em 0;

  user-select: none;

  &.bordered {
    border: solid 0.4em white;
  }

  &.accent {
    background: $color_accent;
    color: white;

    margin-top: 0;
    z-index: 20;
    font-size: 2.25em;
    padding: 0.5em;

  }

  &.button {
    margin-top: 0;
    width: 50%;


    border: white 0.4em solid;

    box-shadow: 5px 5px 5px 3px rgba(0, 0, 0, 0.5);
    cursor: pointer;

    p {
      margin: 0 0;
      padding: 0.3em;

      border-radius: 6px;
      background: linear-gradient(#E1497D, #D91B5C);

      color: white;
      font-size: 2.5em;
      font-weight: 700;
    }

    &.large p {
      padding-top: 1em;
      padding-bottom: 1em;
    }
  }
}
