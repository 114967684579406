@import '../../../../css/common_vars';
$bubble_size: 20rem;

[data-name="mojs-shape"] {
  pointer-events: none;
}

.Bubble {
  color: $color_main;
  background-color: $color_bright;
  border: 0.1rem solid $color_main;

  margin: 0;
  width: auto;
  height: auto;
  vertical-align: middle;
  border-radius: 50%;
  position: absolute;



  left: 0;

  opacity: 0;

  //transition: transform 5s ease-in-out, opacity 0.5s ease-in-out;

  //&.correct {
  //  transition: transform 5s ease-in-out, opacity 0.5s ease-in-out;
  //}

  &.tutorial-highlight {
    z-index: 2000;
    box-shadow: 0 0 1em 0.5em $color_highlight;
  }

  .bubble-container {
    // width and height set in JS
    * {
      pointer-events: none;
    }

    &.active.visible {
      cursor: pointer;
    }

    .content {
      font-size: 1.8em;

      user-select: none;
      transition: opacity 500ms ease-in-out;
      opacity: 1;
      margin: 1em;
      vertical-align: middle;
      display: inline-block;
    }

    .feedback {
      transition: opacity 500ms ease-in-out;

      height: 100%;
      border-radius: 50%;
      box-sizing: border-box;
      color: white;

      opacity: 0;
      margin-top: -$bubble_size;

      &.success {
        background-color: $color_success;
      }

      &.error {
        background-color: $color_error;
      }

      i {
        vertical-align: middle;
        font-size: 10rem;
      }
    }

    &.show-feedback {
      .content {
        opacity: 0;
      }

      .feedback {
        opacity: 1;
      }
    }

  }
}