@import '../../../../css/common_vars.scss';

.ElevatorDoors {
  transition: transform ease-in-out; // Duration set in JS

  $elevator-border-width: 0.5rem;
  $elevator-border: $elevator-border-width solid $color_bright;

  .shaft {
    position: absolute;
    top: -20%;
    left: 10%;
    height: 140%;
    width: 80%;
    margin-top: $elevator-border-width;
    border-left: $elevator-border;
    border-right: $elevator-border;
    box-sizing: border-box;
    z-index: -1;
  }
}