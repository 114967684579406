[data-name="mojs-shape"] {
  pointer-events: none; }

.Bubble {
  color: #0F5E60;
  background-color: #F9D50C;
  border: 0.1rem solid #0F5E60;
  margin: 0;
  width: auto;
  height: auto;
  vertical-align: middle;
  border-radius: 50%;
  position: absolute;
  left: 0;
  opacity: 0; }
  .Bubble.tutorial-highlight {
    z-index: 2000;
    box-shadow: 0 0 1em 0.5em #ff8000; }
  .Bubble .bubble-container * {
    pointer-events: none; }
  .Bubble .bubble-container.active.visible {
    cursor: pointer; }
  .Bubble .bubble-container .content {
    font-size: 1.8em;
    user-select: none;
    transition: opacity 500ms ease-in-out;
    opacity: 1;
    margin: 1em;
    vertical-align: middle;
    display: inline-block; }
  .Bubble .bubble-container .feedback {
    transition: opacity 500ms ease-in-out;
    height: 100%;
    border-radius: 50%;
    box-sizing: border-box;
    color: white;
    opacity: 0;
    margin-top: -20rem; }
    .Bubble .bubble-container .feedback.success {
      background-color: #35B668; }
    .Bubble .bubble-container .feedback.error {
      background-color: #C93534; }
    .Bubble .bubble-container .feedback i {
      vertical-align: middle;
      font-size: 10rem; }
  .Bubble .bubble-container.show-feedback .content {
    opacity: 0; }
  .Bubble .bubble-container.show-feedback .feedback {
    opacity: 1; }
