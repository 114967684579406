@import '../../../css/common_vars';

.OpenQuestionExercise {
  .answers-container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .Button {
      display: flex;
      justify-content: center;
      align-items: center;

      min-height: 5rem;

      width: 45%;
      margin-bottom: 1rem;
      padding: 1.5rem 1rem;

      transition: all 500ms ease-in-out;

      &.selected {
        opacity: 1;

        color: $color_main;
        background-color: $color_bright;
      }
    }
  }

  textarea {
    border: none;
    overflow: auto;
    outline: none;

    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;

    resize: none; /*remove the resize handle on the bottom right*/

    padding: 1rem;
    font-size: 2rem;

    width: 100%;
    box-sizing: border-box;
    height: 14rem;


    margin-bottom: 2rem;
  }
}

.vertical .OpenQuestionExercise {
  textarea {
    height: 28rem;
  }
}