.MatchExercise .answers-container {
  position: relative;
  padding-top: 2em; }
  .MatchExercise .answers-container .timeFinished {
    z-index: 10;
    position: absolute;
    width: 40%;
    left: 30%;
    top: 30%; }
    .MatchExercise .answers-container .timeFinished h1 {
      padding: 3em; }
  .MatchExercise .answers-container .answers-row {
    text-align: justify;
    box-sizing: border-box;
    padding: 0;
    margin-bottom: 1em; }
    .MatchExercise .answers-container .answers-row.center {
      text-align: center; }
    .MatchExercise .answers-container .answers-row .react-draggable-dragged {
      transition: transform 200ms ease-in-out; }
      .MatchExercise .answers-container .answers-row .react-draggable-dragged.react-draggable-dragging {
        transition-duration: 0ms; }
    .MatchExercise .answers-container .answers-row .handle {
      display: inline-block;
      cursor: grab; }
    .MatchExercise .answers-container .answers-row .Card {
      width: 100%;
      margin: 0;
      opacity: 1;
      transition: opacity 500ms ease-in-out; }
      .MatchExercise .answers-container .answers-row .Card.text {
        margin-top: 3em;
        height: auto; }
        .MatchExercise .answers-container .answers-row .Card.text p {
          text-align: center;
          font-size: 1.5em;
          margin: 1.5em 0; }
      .MatchExercise .answers-container .answers-row .Card.answer-image .front {
        transition: opacity 500ms ease-in-out;
        opacity: 1;
        height: 15em; }
        .MatchExercise .answers-container .answers-row .Card.answer-image .front .image {
          border: 6px solid white;
          border-radius: 5px;
          box-sizing: border-box;
          height: 100%;
          background-size: cover; }
      .MatchExercise .answers-container .answers-row .Card.answer-image .back {
        transition: opacity 500ms ease-in-out;
        opacity: 0;
        height: 15em;
        margin-top: -15em; }
        .MatchExercise .answers-container .answers-row .Card.answer-image .back .feedback {
          height: 100%;
          border: solid white 6px;
          border-radius: 5px;
          box-sizing: border-box;
          color: white; }
          .MatchExercise .answers-container .answers-row .Card.answer-image .back .feedback.success {
            background: linear-gradient(#00c618, rgba(0, 198, 24, 0.8)); }
          .MatchExercise .answers-container .answers-row .Card.answer-image .back .feedback.error {
            background: linear-gradient(#ff0d00, rgba(255, 13, 0, 0.8)); }
          .MatchExercise .answers-container .answers-row .Card.answer-image .back .feedback .icon {
            height: 100%;
            text-align: center; }
            .MatchExercise .answers-container .answers-row .Card.answer-image .back .feedback .icon .feedback-icon {
              vertical-align: middle;
              display: inline-block;
              font-size: 6em; }
          .MatchExercise .answers-container .answers-row .Card.answer-image .back .feedback .text {
            height: 100%;
            text-align: center;
            margin: 0; }
            .MatchExercise .answers-container .answers-row .Card.answer-image .back .feedback .text .feedback-text {
              vertical-align: middle;
              display: inline-block;
              font-size: 2.5em;
              font-weight: 700; }
              .MatchExercise .answers-container .answers-row .Card.answer-image .back .feedback .text .feedback-text .smaller {
                font-size: 0.5em;
                font-weight: 300; }
      .MatchExercise .answers-container .answers-row .Card.answer-image.show-feedback .front {
        opacity: 0; }
      .MatchExercise .answers-container .answers-row .Card.answer-image.show-feedback .back {
        opacity: 1; }
    .MatchExercise .answers-container .answers-row .react-draggable-dragging .Card.text {
      transition: none; }
    .MatchExercise .answers-container .answers-row .fake-element {
      display: inline-block;
      height: 1em; }
  .MatchExercise .answers-container .answers-row:after {
    content: '';
    width: 100%;
    /* Ensures justification for single lines */
    display: inline-block;
    height: 0.5em; }
.MatchExercise .buttons-container {
  margin-top: -5em;
  z-index: 20; }
  .MatchExercise .buttons-container .center {
    z-index: 20; }
    .MatchExercise .buttons-container .center .next-button {
      z-index: 20; }

/*# sourceMappingURL=OldMatchExercise.css.map */
