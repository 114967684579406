.MatchExercise {
  position: relative;
  width: 100%;
  height: 100%;

  .MatchCategories {
    margin-bottom: 2rem;
  }
}

.vertical .MatchExercise {
  .MatchCategories {
    margin-bottom: 3rem;
  }
}