@import '../../../vars.scss';

.vertical {
  .PointsBar {
    .OldCard.instruction {
      max-width: 24em;
    }
  }
}

.PointsBar {
  .OldCard {
    text-align: center;
    font-size: 2em;

    height: 100%;
    width: 5em;

    padding: 0.5%;
    margin: 0 0 0.5em 0.5em;

    .fas {
      padding-right: 5%;
    }

    &.instruction {
      float:left;
      margin-left:0;
      width: auto;
      max-width: 28em;

      div {
        min-height: 1.2em;
        line-height: 1.2em;

        p {
          font-size: 0.75em;
          margin: 0 0.5em;
        }
      }
    }

    &.clock {
      &.warning {
        //transition: 600ms ease-in-out;
        color: white;
        background: $gradient_error;
      }
    }
  }
}