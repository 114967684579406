@import '../../../vars.scss';


.vertical .OldMessageScreen {
  .message {
    margin-top: 1em;
    width: 95%;

    .content {
      width: 100%;
      font-size: 1.2em;

      p {
        margin: 0.5em 0;
      }
    }

    .image {
      padding-top: 3em;
      width: 50%;
    }
  }
}

.OldMessageScreen {
  .message {
    margin-top: 10em;
    width: 80%;

    color: #f1fcfd;
    background: #0F5E60;
    border-radius: 0;
    box-shadow: none;
    border: none;

    &.big-image {
      margin-top: 1em;

      .content {
        width: 100%;
      }

      .image {
        width: 100%;
      }
    }

    & > div {
      display: inline-block;
      vertical-align: middle;
    }

    .image{
      width: 33.333%;
      padding: 0.5em;
      box-sizing: border-box;

      img {
        vertical-align: middle;
        width: 100%;
        border: 0.5rem solid #f1fcfd;
        box-sizing: border-box;
        border-radius: 0;
      }
    }

    .content {
      width: 66.667%;
      padding: 0.5em;
      box-sizing: border-box;

      p {
        font-size: 1.5em;

        a {
          color: $color_main;
          text-decoration: none;
          font-weight: 700;

          &:hover {
            text-decoration: underline;
          }
        }

        &.browser-warning {
          font-size: 1em;
          color: red;
          margin: 0;
        }

        &.for-visa {
          padding-top: 3em;
          height: 2em;
          line-height: 2em;
          vertical-align: middle;
          margin: 0;

          img {
            height: 2em;
          }
        }
      }
    }
  }
}