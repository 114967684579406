@import '../../../css/common_vars';

.AnswerCard {
  position: relative;
  background-color: $color_clickable;

  height: 12rem;

  cursor: pointer;
  user-select: none;
  transition: all 500ms ease-in-out;


  &:hover {
    background-color: $color_clickable_hover;
  }

  & > div {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;

    display: flex;
    align-items: center;
    justify-content: center;

    transition: all 500ms ease-in-out;
  }

  .content {
    opacity: 1;

    font-size: 2rem;
    font-weight: 700;
    color: #FFFFFF;
  }

  .feedback {
    opacity: 0;

    color: #FFFFFF;
    background-color: $color_error;

    i {
      font-size: 8rem;
    }

    .feedback-description {
      font-size: 2rem;
      margin-left: 4rem;

      & > * {
        margin: 0;
      }

      h2 {
        font-size: 4rem;
        margin-bottom: 0.5rem;
      }
    }

    &.correct {
      background-color: $color_success;
    }
  }

  &.blurred {
    .content {
      filter: blur(8px);
    }
  }

  .disabled-overlay {
    background-color: #DDDDDD;

    opacity: 0;
  }

  &.disabled {
    opacity: 0.6;
    cursor: default;
    background-color: $color_main;

    &:hover {
      background-color: $color_main;
    }

    .disabled-overlay {
      opacity: 0.2;
    }

    &.correct {
      opacity: 1;
      background-color: $color_success;

      .disabled-overlay {
        display: none;
      }
    }
  }

  &.show-feedback {
    cursor: default;

    .feedback {
      opacity: 1;
    }
  }
}