.AnimatedElement {
  &.full-size {
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
  }

  animation-fill-mode: both;
  animation-iteration-count: 1;
  animation-play-state: running;
  animation-direction: normal;

  &.animation-fade {
    opacity: 0;

    animation-name: fadeOut;
    animation-timing-function: ease-in-out;

    &.visible {
        animation-name: fadeIn;
    }
  }

  &.animation-slide-left {
    transform: translateX(-100rem);

    animation-name: slideLeftHide;
    animation-timing-function: ease-in-out;

    &.visible {
      animation-name: slideLeftShow;
    }
  }

  &.animation-slide-up {
    transform: translateX(-100rem);

    animation-name: slideUpHide;
    animation-timing-function: ease-in-out;

    &.visible {
      animation-name: slideUpShow;
    }
  }

  &.animation-pop-out {
    transform: scale(0);
    transform-origin: center;

    animation-name: popOutHide;
    animation-timing-function: ease-in-out;

    &.visible {
      animation-name: popOutShow;
    }
  }

  &.hidden {
    display: none;
  }
}

@keyframes slideLeftHide {
  0%   {transform: translateX(0)}
  100% {transform: translateX(-100rem)}
}

@keyframes slideLeftShow {
  0%   {transform: translate3d(-100rem,0,0)}
  60%  {transform: translate3d(2rem,0,0)}
  100% {transform: translate3d(0,0,0)}
}

@keyframes slideUpHide {
  0%   {transform: translate3d(0,0,0)}
  100% {transform: translate3d(0,-100rem,0)}
}

@keyframes slideUpShow {
  0%   {transform: translate3d(0,100rem,0)}
  70%  {transform: translate3d(0,-5rem,0)}
  100% {transform: translate3d(0,0,0)}
}

@keyframes popOutHide {
  0%   {transform: scale(1)}
  100% {transform: scale(0)}
}

@keyframes popOutShow {
  0%   {transform: scale(0)}
  60%  {transform: scale(1.2)}
  100% {transform: scale(1)}
}

@keyframes fadeOut {
  0%   {opacity: 1}
  100% {opacity: 0}
}

@keyframes fadeIn {
  0%   {opacity: 0}
  100% {opacity: 1}
}