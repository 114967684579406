.OpenQuestionExercise {
  padding-top: 1em;
}
.OpenQuestionExercise .instruction-container {
  margin-bottom: 1em;
}
.OpenQuestionExercise .instruction-container .Card {
  margin: 0;
}
.OpenQuestionExercise .instruction-container .Card p {
  font-size: 1.5em;
  font-weight: 700;
}
.OpenQuestionExercise .answers-container .image-container {
  padding: 0 1em 1em 0;
  box-sizing: border-box;
}
.OpenQuestionExercise .answers-container .image-container .image {
  width: 100%;
  height: 100%;
  margin: 0;
  border: 6px solid white;
}
.OpenQuestionExercise .answers-container .image-container .image div {
  width: 100%;
  height: 100%;
  border-radius: 5px;
  background-position: center;
  background-size: cover;
}
.OpenQuestionExercise .answers-container .answers {
  text-align: justify;
  margin-bottom: -1em;
}
.OpenQuestionExercise .answers-container .answers:after {
  content: "";
  width: 100%;
  /* Ensures justification for single lines */
  display: inline-block;
  height: 0.5em;
}
.OpenQuestionExercise .answers-container .answers .answer {
  display: inline-block;
  font-size: 1.25em;
  width: 48%;
  margin-bottom: 1em;
  transition: none;
  text-align: center;
}
.OpenQuestionExercise .answers-container .answers .answer.full {
  width: 100%;
}
.OpenQuestionExercise .answers-container .answers .answer.chosen {
  background: linear-gradient(#00c618, rgba(0, 198, 24, 0.8));
  color: white;
}
.OpenQuestionExercise .description-container {
  margin-bottom: 1em;
}
.OpenQuestionExercise .description-container p {
  margin-top: 0;
}
.OpenQuestionExercise .description-container .Card {
  transition: 500ms;
  margin: 0;
}
.OpenQuestionExercise .description-container .Card .text {
  box-sizing: border-box;
  width: 100%;
  padding: 1em;
  font-size: 1.25em;
}
.OpenQuestionExercise .description-container .Card .text textarea {
  box-sizing: border-box;
  width: 100%;
  height: 10em;
  padding: 1em;
  border: none;
  box-shadow: inset 0 0 5px 1px rgba(0, 0, 0, 0.75);
}
.OpenQuestionExercise .slide-enter {
  height: 0;
}
.OpenQuestionExercise .slide-enter.slide-enter-active {
  height: 7em;
  transition: height 500ms ease-in-out;
}
.OpenQuestionExercise .slide-appear {
  height: 0;
}
.OpenQuestionExercise .slide-appear.slide-appear-active {
  height: 7em;
  transition: height 500ms ease-in-out;
}
.OpenQuestionExercise .slide-leave {
  height: 0;
}
.OpenQuestionExercise .slide-leave.slide-leave-active {
  height: 7em;
  transition: height 500ms ease-in-out;
}
.OpenQuestionExercise .messages-container .messages-row {
  height: 7em;
}
.OpenQuestionExercise .messages-container .messages-row .message {
  margin-top: 0;
  background: linear-gradient(#ff0d00, rgba(255, 13, 0, 0.8));
  border: 6px solid white;
}
.OpenQuestionExercise .messages-container .messages-row .message p {
  color: white;
  font-size: 1.5em;
  font-weight: 700;
}
.OpenQuestionExercise .buttons-container .next-button.disabled {
  opacity: 0.75;
}
.OpenQuestionExercise .buttons-container .next-button .fas {
  margin-right: 0.25em;
}

/*# sourceMappingURL=OldOpenQuestionExercise.css.map */
