$mq_lg: "screen and (min-width: 1220px) and (min-height: 750px)";
$mq_md: "screen and (min-width: 1020px) and (min-height: 600px)";
$mq_sm: "screen and (min-width: 820px) and (min-height: 450px)";

$lg_width: 1200px;
$lg_height: 750px;

$md_width: 1000px;
$md_height: 600px;

$sm_width: 800px;
$sm_height: 450px;

// Main color: #0f75bc
// Accent color: #d91b5c

$blue-bg: #0071BC;

$default-font-family: "Lato", sans-serif;

$color_main: #0f75bc;
$color_accent: #d91b5c;
$color_success: rgb(0, 198, 24);
$color_error: rgb(255, 13, 0);
$color_highlight: rgb(255, 128, 0);

$gradient_success: linear-gradient(rgba(0, 198, 24, 1), rgba(0, 198, 24, 0.8));
$gradient_error: linear-gradient(rgba(255, 13, 0, 1), rgba(255, 13, 0, 0.8));
$gradient_gray: linear-gradient(#fff, #eee);