.App {
  text-align: center;
}

.react-draggable {
  cursor: grab;
  
  &.react-draggable-dragging {
    cursor: grabbing;
  }

  &.react-draggable-dragged:not(.react-draggable-dragging) {
    transition: transform 500ms ease-in-out;
  }
}

