@import url('./css/pure-min.css');
/*@import url('https://use.fontawesome.com/releases/v5.0.2/css/all.css');*/
@import url('./css/main.scss');
@import url('./css/csshake.min.css');

body {
  margin: 0;
  padding: 0;
  font-family: "Lato", sans-serif;
}

body * {
  touch-action: manipulation;
}