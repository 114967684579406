.VideoScreen .title-container .Card {
  width: 80%; }

.VideoScreen .video-container {
  padding-top: 2em; }
  .VideoScreen .video-container .Card {
    width: 80%;
    border: solid 5px white; }
    .VideoScreen .video-container .Card video {
      width: 100%;
      height: auto;
      display: block;
      border-radius: 6px; }
