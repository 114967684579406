@import '../../../vars.scss';

.QuestionExercise {
  .points-bar-container {
    height: 10%;
  }

  .question-container {
    .question {
      width: 100%;
      margin-left: 0;
      margin-right: 0;

      font-size: 2em;

      p {
        margin: 2% 0;
      }
    }
  }

  .answers-container {
    .answer {
      margin: 0 0 5%;
      width: 90%;

      box-shadow: 5px 5px 5px 3px rgba(0, 0, 0, 0.5);
      cursor: pointer;

      transition: 600ms;
      transform-style: preserve-3d;
      transform: translateZ(-1px);

      .image {
        margin: 0;
        background-size: cover;
        border-radius: 5px;
        height: 13em;

        img {
          display: none;
        }
      }

      .feedback {
        border-radius: 6px;
        color: white;
        height: 100%;

        &.success {
          background: linear-gradient(rgba(0, 198, 24, 1), rgba(0, 198, 24, 0.8));
        }

        &.error {
          background: linear-gradient(rgba(255, 13, 0, 1), rgba(255, 13, 0, 0.8));
        }

        .icon {
          height: 100%;
          text-align: center;
          box-sizing: border-box;

          .feedback-icon {
            vertical-align: middle;
            display: inline-block;

            font-size: 7em;
          }
        }

        .text {
          height: 100%;
          text-align: center;
          margin: 0;
          box-sizing: border-box;

          .feedback-text {
            vertical-align: middle;
            display: inline-block;

            font-size: 2.5em;
            font-weight: 700;

            .smaller {
              font-size: 0.6em;
              font-weight: 300;
            }
          }
        }
      }

      // Animation CSS
      position: relative;

      .front, .back {
        backface-visibility: hidden;
      }

      .front {
        /* for firefox 31 */
        transform: rotateY(0deg) translateZ(1px);
      }

      .back {
        cursor: default;
        transform: rotateY(180deg) translateZ(1px);
        height: 13em;
        margin: -13em 0 0 0;
      }
    }

    & .answer.flip {
      transform: rotateY(180deg);
    }

    .hide .OldCard.answer {
      cursor: default;
      opacity: 0.01;
      transition: opacity .5s ease-in;

      .front {
        opacity: 0;
      }

      .back {
        opacity: 0.01;
        transition: opacity .5s ease-in;
        backface-visibility: visible;
      }

    }
  }


  .buttons-container {
    .next-button {
      margin-top: 0;
      width: 50%;


      border: white 0.4em solid;

      box-shadow: 5px 5px 5px 3px rgba(0, 0, 0, 0.5);
      cursor: pointer;

      p {
        margin: 0 0;
        padding: 0.3em;

        border-radius: 6px;
        background: linear-gradient(#E1497D, #D91B5C);

        color: white;
        font-size: 2.5em;
        font-weight: 700;
      }
    }
  }
}