.ElevatorExercise {
  .answers {
    display: flex;
    justify-content: space-around;
    align-items: flex-start;

    position: relative;

    .ElevatorButtons {
      width: 20%
    }

    .ElevatorDoors {
      width: 40%;
    }

  }

  .fixed {
    width: 100%;
    position: absolute;
    bottom: 5rem;
  }

  .last-chance {
    padding: 2rem 1rem;
    font-size: 2.5rem;
    font-weight: 700;
  }
}

.vertical .ElevatorExercise {
  .answers {
    justify-content: space-between;

    .ElevatorDoors {
      width: 70%;
    }
  }
}