@import '../../../../css/common_vars.scss';

.ElevatorCard {
  flex-direction: column;
  justify-content: space-around;
  position: relative;
  margin-bottom: 30rem;

  .content {
    font-size: 2rem;
  }

  .left-door, .right-door {
    position: absolute;
    top: -0.5%;

    background-color: $color_bright;

    box-sizing: border-box;
    width: 50.5%;
    height: 101%;

    transition: transform ease-in-out;

    &.left-door {
      left: -0.5%;
      border-right: 0.1rem solid $color_main;
      transform-origin: left;
    }

    &.right-door {
      left: 50%;
      border-left: 0.1rem solid $color_main;
      transform-origin: right;
    }
  }

  &.open {
    .left-door, .right-door {
      transform: scaleX(0);
    }
  }

  & .line {
    position: absolute;
    top: 100%;
    width: 1rem;
    background-color: $color_bright;
  }

  $elevator-border-width: 0.5rem;
  $elevator-border: $elevator-border-width solid $color_bright;

  & .shaft {
    position: absolute;
    top: 100%;
    width: 80%;
    margin-top: $elevator-border-width;
    border-left: $elevator-border;
    border-right: $elevator-border;
    box-sizing: border-box;
  }

  border: $elevator-border;
}

