@import '../../../../css/common_vars';

@keyframes animation-balloon-pop-balloon {
  0% {
    transform: scale3d(1, 1, 1);
    opacity: 1;
  }
  10% {
    transform: scale3d(1.2, 1.2, 1);
    opacity: 1;
  }
  30% {
    transform: scale3d(0.8, 0.8, 1);
    opacity: 1;
  }
  40% {
    transform: scale3d(0.5, 0.9, 1);
    opacity: 1;
  }
  70% {
    transform: scale3d(0.1, 0.8, 1);
    opacity: 0;
  }
  100% {
    transform: scale3d(0.1, 0.8, 1);
    opacity: 0;
  }
}

@keyframes animation-balloon-pop-box {
  0% {
    transform: translate3d(0, 0, 0);
  }
  10% {
    transform: translate3d(0, 0.2rem, 0);
  }
  20% {
    transform: translate3d(0, 0.2rem, 0);
  }
  30% {
    transform: translate3d(0, -0.2rem, 0);
  }
  100% {
    transform: translate3d(0, 70rem, 0);
  }
}

@keyframes animation-balloon-popout {
  0% {
    transform: scale3d(1, 1, 1);
  }
  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
  100% {
    transform: scale3d(1, 1, 1);
  }
}

@keyframes animation-balloon-holes-parachute {
  0% {
    transform: scale3d(1, 1, 1);
  }
  50% {
    transform: scale3d(1, 1, 1);
  }
  75% {
    transform: scale3d(0.1, 1, 1);
  }
  100% {
    transform: scale3d(0.001, 1, 1);
  }
}

@keyframes animation-balloon-holes-box {
  0% {
    transform: translate3d(0, 0, 0);
  }
  75% {
    transform: translate3d(0, 0, 0);
  }
  100% {
    transform: translate3d(0, 70rem, 0);
  }
}

.RiseFallExercise {
  .RiseFallCard {
    opacity: 0;
    position: absolute;

    &.show-feedback {
        .attachment {
          opacity: 0;
        }
    }

    .attachment {
      display: block;
      position: absolute;
      height: 20rem;
      top: -20.25rem;
      background-image: url('../img/baloon.svg');
      background-repeat: no-repeat;
      background-position: bottom;
      background-size: contain;

      transition: opacity 500ms ease-in-out;

      z-index: 0;
    }

    &.falling {
      .attachment {
        background-image: url('../img/parachute.svg');
      }
    }

    .answer {
      // top set in JS
      margin: 0;
      width: auto;
      height: auto;
      vertical-align: middle;
      border-radius: 0.25em;
      position: relative;

      left: 0;

      transition: transform 5s ease-in-out, opacity 0.5s ease-in-out;

      &.tutorial-highlight {
        z-index: 2000;
        box-shadow: 0 0 1em 0.5em rgba(255, 255, 255, 0.1);
      }

      &.active.visible {
        cursor: pointer;
      }

      .content, .feedback {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .content {
        width: 100%;
        height: 100%;
        font-size: 1.75rem;
        padding: 1rem;
        box-sizing: border-box;

        background-color: $color_bright;
        color: $color_main;

        transition: opacity 500ms ease-in-out;
        opacity: 1;
      }

      .feedback {
        transition: opacity 500ms ease-in-out;

        height: 100%;
        color: white;

        opacity: 0;

        &.success {
          background-color: $color_success;
        }

        &.error {
          background-color: $color_error;
        }

        i {
          font-size: 5em;
        }
      }

      &.show-feedback {
        .content {
          opacity: 0;
        }

        //.feedback {
        //  opacity: 1;
        //}
      }
    }
  }
}