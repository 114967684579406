.RevealExercise {
  .answers {
    max-height: 35rem;
    overflow-y: auto;

    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-right: 1rem;

    &.inactive {
      .RevealExerciseCard {
        opacity: 0.6;
      }
    }

    .RevealExerciseCard {
      width: 49%;
      height: 8rem;

      font-size: 2rem;

      margin-bottom: 1rem;
    }
  }
}

.vertical .RevealExercise {
  .answers {
    max-height: 50rem;

    .RevealExerciseCard {
      width: 100%;
    }
  }
}