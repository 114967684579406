@import '../../../css/common_vars';

.MemoryExercise {
  .answers-container {
    width: 100%;

    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    position: relative;
    padding-top: 1em;

    &.game-finished {
      .MemoryCard {
        opacity: 0.5;
      }
    }

    .MemoryCard {
      box-sizing: border-box;
      width: 15rem;
      margin-bottom: 1rem;

      .front, .back {
        width: 100%;
        height: 10rem;
      }

      .back {
        margin-top: -10rem;
      }
    }

    &.large {
      .MemoryCard {
        width: 18rem;

        .front, .back {
          height: 12rem;
        }

        .back {
          margin-top: -12rem;
        }
      }
    }
  }

  .time-finished-container, .next-button-container {
    position: relative;
  }

  .time-finished-container{
    top: -30rem;
  }

  .next-button-container {
    top: -25rem;
  }

  &.only-images {
    .MemoryCard .front .text {
      background: none;
    }
  }
}

.vertical .MemoryExercise {
  .answers-container .MemoryCard {
    padding: 0.25rem;
    width: 12rem;

    .front, .back {
      height: 8rem;
    }

    .back {
      margin-top: -8rem;
    }
  }

  .answers-container.large .MemoryCard {
    width: 15rem;

    .front, .back {
      height: 10rem;
    }

    .back {
      margin-top: -10rem;
    }
  }
}