@import '../../../css/common_vars';

.FeedbackCard {
  position: absolute;
  bottom: 10rem;

  display: flex;
  width: 100%;
  justify-content: center;

  .feedback {
    text-align: center;
    
    position: relative;
    z-index: 101;

    padding: 2rem;

    color: #FFFFFF;
    background-color: $color_error;

    width: 100%;
    font-size: 2rem;

    &.success {
      background-color: $color_success;
    }
  }
}